<template>
	<div>
		<div class="ui-welcome" v-if="!$store.state.system.permissionCodes.includes('index_panel')">欢迎登录</div>
		<a-row class="ui-row" v-permission="['index_panel']">
			<a-col class="ui-card">
				<a-spin :spinning="loading">
					<div class="ui-card__header">
						累计用户数
						<a-tooltip>
							<template #title>
								<div>
									该数据为您展示：今日、本周、本月的累计用户数
								</div>
								<div>
									算法说明：小程序授权手机号注册登录的新用户总和
								</div>
							</template>
							<Icon icon="ExclamationCircleOutlined"></Icon>
						</a-tooltip>
					</div>
					<div class="ui-card__num">{{ registerUser.totalQuantity || 0 }}</div>
					<div class="ui-card__item ui-flex__between">
						<div>本周：<span>{{ registerUser.weekQuantity || 0 }}</span></div>
						<div>本月：<span>{{ registerUser.monthQuantity || 0 }}</span></div>
					</div>
					<div class="ui-card__footer">
						当日新增用户数(人)：<span>{{ registerUser.todayQuantity || 0 }}</span>
					</div>
				</a-spin>
			</a-col>
			<a-col class="ui-card">
				<a-spin :spinning="loading">
					<div class="ui-card__header">
						累计会员数
						<a-tooltip>
							<template #title>
								<div>
									该数据为您展示：今日、本周、本月的累计会员数
								</div>
								<div>
									算法说明：小程序下单成功的新用户数
								</div>
							</template>
							<Icon icon="ExclamationCircleOutlined"></Icon>
						</a-tooltip>
					</div>
					<div class="ui-card__num">{{ buyUser.totalQuantity || 0 }}</div>
					<div class="ui-card__item ui-flex__between">
						<div>本周：<span>{{ buyUser.weekQuantity || 0 }}</span></div>
						<div>本月：<span>{{ buyUser.monthQuantity || 0 }}</span></div>
					</div>
					<div class="ui-card__footer">
						今日新增会员数：<span>{{ buyUser.todayQuantity || 0 }}</span>
					</div>
				</a-spin>
			</a-col>
			<a-col class="ui-card">
				<a-spin :spinning="loading">
					<div class="ui-card__header">
						企微用户数
						<a-tooltip>
							<template #title>
								<div>
									该数据为您展示：今日、本周、本月的企业用户数
								</div>
								<div>
									算法说明：用户添加企业微信好友的总和
								</div>
							</template>
							<Icon icon="ExclamationCircleOutlined"></Icon>
						</a-tooltip>
					</div>
					<div class="ui-card__num">{{ followUser.totalQuantity || 0 }}</div>
					<div class="ui-card__item ui-flex__between">
						<div>本周：<span>{{ followUser.weekQuantity || 0 }}</span></div>
						<div>本月：<span>{{ followUser.monthQuantity || 0 }}</span></div>
					</div>
					<div class="ui-card__footer">
						今日新增企微用户数(人)：<span>{{ followUser.todayQuantity || 0 }}</span>
					</div>
				</a-spin>
			</a-col>

			<a-col class="ui-card">
				<a-spin :spinning="incomeLoading">
				<div class="ui-card__header">
					今日收入
					<a-tooltip>
						<template #title>
							<div>
								该数据为您展示：今日所有商城+卖品+影票（微信支付购票）的总收入
							</div>
						</template>
						<Icon icon="ExclamationCircleOutlined"></Icon>
					</a-tooltip>
				</div>
				<div class="ui-card__num">{{ todayIncomeData.count || 0 }}</div>
				<div class="ui-card__item">
					<div>商城：<span>￥{{ todayIncomeData.goodsPrice || 0 }}</span></div>
					<div>卖品：<span>￥{{ todayIncomeData.snackPrice || 0 }}</span></div>
					<div>影票：<span>￥{{ todayIncomeData.ticketPrice || 0 }}</span></div>
				</div>
				</a-spin>
			</a-col>

			<a-col class="ui-card">
				<a-spin :spinning="incomeLoading">
				<div class="ui-card__header">
					本月收入
					<a-tooltip>
						<template #title>
							<div>
								该数据为您展示：本月所有商城+卖品+影票（微信支付购票）的总收入
							</div>
						</template>
						<Icon icon="ExclamationCircleOutlined"></Icon>
					</a-tooltip>
				</div>
				<div class="ui-card__num">{{ monthIncomeData.count || 0 }}</div>
				<div class="ui-card__item">
					<div>商城：<span>￥{{ monthIncomeData.goodsPrice || 0 }}</span></div>
					<div>卖品：<span>￥{{ monthIncomeData.snackPrice || 0 }}</span></div>
					<div>影票：<span>￥{{ monthIncomeData.ticketPrice || 0 }}</span></div>
				</div>
				</a-spin>
			</a-col>

			<a-col class="ui-card">
				<a-spin :spinning="seatLoading">
				<div class="ui-card__header">
					今日观影人数
					<a-tooltip>
						<template #title>
							<div>
								该数据为您展示：今日、本周、本月、本年的观影人次
							</div>
							<div>
								算法说明：成功出票的张数
							</div>
						</template>
						<Icon icon="ExclamationCircleOutlined"></Icon>
					</a-tooltip>
				</div>
				<div class="ui-card__num">{{ seatData.day || 0 }}</div>
				<div class="ui-card__item ui-flex__between">
					<div>本周：<span>{{ seatData.week || 0 }}</span></div>
					<div>本月：<span>{{ seatData.month || 0 }}</span></div>
				</div>
				<div class="ui-card__footer">
					本年观影人次(人)：<span>{{ seatData.year || 0 }}</span>
				</div>
				</a-spin>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		getHomeUserData, getHomeIncomeData, getHomeSeatData
	} from '@/service/modules/report.js';
	export default {
		components: {
			Icon
		},
		data() {
			return {
				loading: false,
				incomeLoading: false,
				followUser: {},
				registerUser: {},
				buyUser: {},
				incomeLoading: false,
				monthIncomeData: {},
				todayIncomeData: {},
				seatLoading: false,
				seatData: {}
			}
		},
		created() {
			this.getHomeUserData();
			this.getHomeIncomeData();
			this.getHomeSeatData();
			//console.log(this.organizationList, 1111)
		},
		methods: {
			async getHomeUserData() {
				this.loading = true;
				try {
					let ret = await getHomeUserData({});
					this.loading = false;
					if (ret.code === 200) {
						this.followUser = ret.data.followUser;
						this.registerUser = ret.data.registerUser;
						this.buyUser = ret.data.buyUser;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getHomeIncomeData() {
				this.incomeLoading = true;
				try {
					let ret = await getHomeIncomeData({});
					this.incomeLoading = false;
					if (ret.code === 200) {
						this.monthIncomeData = {};
						ret.data.monthIncomeData.forEach(item => {
							if (item.saleType === 1) {
								this.monthIncomeData.goodsPrice = item.payment;
							}
							if (item.saleType === 2) {
								this.monthIncomeData.snackPrice = item.payment;
							}
							if (item.saleType === 3) {
								this.monthIncomeData.ticketPrice = item.payment;
							}
							this.monthIncomeData.count = ((Number(this.monthIncomeData.count) || 0) + item.payment).toFixed(2);
						});
						this.todayIncomeData = {};
						ret.data.todayIncomeData.forEach(item => {
							if (item.saleType === 1) {
								this.todayIncomeData.goodsPrice = item.payment;
							}
							if (item.saleType === 2) {
								this.todayIncomeData.snackPrice = item.payment;
							}
							if (item.saleType === 3) {
								this.todayIncomeData.ticketPrice = item.payment;
							}
							this.todayIncomeData.count = ((Number(this.todayIncomeData.count) || 0) + item.payment).toFixed(2);
						});
					}
				} catch(e) {
					this.incomeLoading = false;
				}
			},
			async getHomeSeatData() {
				this.seatLoading = true;
				try {
					let ret = await getHomeSeatData({});
					this.seatLoading = false;
					if (ret.code === 200) {
						this.seatData = ret.data;
					}
				} catch(e) {
					this.seatLoading = false;
				}
			}
		}
	}
</script>

<style scoped>
	.ui-welcome {
		font-size: 36px;
		line-height: 250px;
		text-align: center;
	}
	.ui-row {
		margin: 0 -10px 20px;
	}

	.ui-card {
		width: 31.3%;
		padding: 20px;
		margin: 20px 10px 10px;
		box-shadow: 0px 0px 13px 5px rgba(136, 137, 139, 0.06);
		border: 1px solid rgba(136, 137, 139, 0.2);
	}

	.ui-card__header {
		font-weight: 600;
		font-size: 16px;
	}

	.ui-card__num {
		margin-top: 10px;
		margin-bottom: 20px;
		font-size: 32px;
		line-height: 52px;
		font-weight: 500;
		color: black;
	}

	.ui-card__item {
		/* height: 22px; */
	}

	.ui-flex__between {
		display: flex;
		justify-content: space-between;
	}

	.ui-card__footer {
		margin-top: 10px;
		padding: 10px 0 0;
		border-top: 1px solid rgba(136, 137, 139, 0.1);
	}
	
	span {
		font-weight: 500;
		color: #000;
	}
</style>
